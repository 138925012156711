.float-left {
    float: left;
}

.float-right {
    float: right;
}

.center {
    text-align: center;
}

.login-form {
    width: 25%;
    border-radius: 5%;
    border: solid black 1px;
    padding: 10px;
    margin: auto;
}

.pet-card:hover{
    border-color: #fea910;
    border-top: none;
    border-width: 3px;
    cursor: pointer;
}

.pet-header {
    background-color: #fea910;
}

.form-header {
    background-color: #fea910;
}

.red-text {
    color: red;
}

.new-assignment-card {
    width: 50%;
    margin: auto;
    padding: 10px;
    border: solid black 1px;
    border-radius: 5%;
}

.success-text {
    color: green;
}

.error-text {
    color: red;
}

.markdown-editor {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: 10px; /* Adjust the gap between columns */
}

.markdown-text-area {
    width: 100%;
    height: 90vh;
    margin-top: 0px;
    border-top: none;
}

.article-preview {
    border-radius: 5px;
    border: 1px solid black;
    padding: 10px;
    height: 100%;
}

.grid-2 {
    display: grid;
    grid-template-columns: 1fr 2fr;
}

.add-buttons {
    background-color: #fea910;
}

.article-editor-toolbar {
    border: 1px solid black;
    margin-bottom: 0px;
}

.article-toolbar-container {
    padding: 10px;
}

.editor-container {
    padding-left: 10px;
    padding-right: 10px;
}

.image-selector-container {
    text-align: center;
}

.picture-card-click:hover {
    cursor: pointer;
}

.article-preview p {
    font-size: 18px;
}

.article-preview li {
    font-size: 18px;
}

.article-preview img {
    border-radius: 5px;
}

.article-preview h1 {
    font-size: 60px;
    padding-bottom: 10px;
}

.article-preview a {
    font-weight: bold;
    color: #fea910;
}

.title-buttons-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.title-button {
    border-radius: 5px;
    border-bottom: 2px solid lightgray;
}

.title-button:hover {
    border-bottom: 2px solid #fea910;
    cursor: pointer;
}

.title-button-active {
    border-radius: 5px;
    border-bottom: 2px solid #fea910;
}

.title-button-active:hover {
    cursor: pointer;
}

.form-section {
    border: 1px solid black;
    margin-top: 0px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.form-section-form {
    padding: 10px;
}

.form-section-name {
    margin-bottom: 0px;
    background-color: #fea910;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding: 5px;
}

.hide {
    display: none;
}

.living-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 20px;
}

.living-grid div:hover {
    /* border-radius: 5px;
    border: 1px solid blue; */
    cursor: pointer;
}

.selected-living {
    border-radius: 5px;
    border: 1px solid blue;
    cursor: pointer;
}

.requirement-text-area {
    width: 100%;
}

.add-button {
    margin: 10px;
}

.inside-component {
    padding: 20px;
}

.form-section-name-inner {
    margin-bottom: 0px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding: 5px;
}

.button-group-bottom {
    display: flex;
    gap: 10px;
}

.buttons {
    margin-top: 10px;
}

.clear-div {
    clear: both;
}

.boxed {
    border-radius: 5px;
    border: 1px solid black;
    margin-left: 10px;
}

.bordered {
    border-radius: 5px;
    border: 1px solid black;
    padding: 10px;
}